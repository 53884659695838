.leave-creation-wrapper {
	.leave-day-attribute-label {
		white-space: nowrap;
	}

	/* stylelint-disable-next-line media-feature-name-value-allowed-list */
	@media (max-width: 1825px) {
		.swap-btn-label {
			display: none;
		}
	}

	/* stylelint-disable-next-line media-feature-name-value-allowed-list */
	@media (max-width: 1650px) {
		.checkbox-label {
			display: none;
		}
	}

	/* stylelint-disable-next-line media-feature-name-value-allowed-list */
	@media (max-width: 1470px) {
		.amount-selected-label {
			display: none;
		}
	}

	/* stylelint-disable-next-line media-feature-name-value-allowed-list */
	@media (max-width: 1290px) {
		.leave-day-attribute-label {
			display: none;
		}
	}
}

.leave-days-calendar .day-header-content {
	backdrop-filter: blur(7px);
}

.leave-days-calendar :where(p-month-cell, .day-wrapper):has(p-checkbox) {
	// Check the cells that have icons with danger, and use a variation of our danger color for the background color of the header and the content, as it means that something is wrong.
	$primary-adjusted-for-today: adjust-color($primary, $saturation: -40%, $lightness: -10%);

	&:has(.leave-day-grid fa-icon.text-danger) {
		background-color: $ultra-light-danger;

		.day-header-content,
		p-cell-top {
			/* stylelint-disable-next-line declaration-no-important */
			background-color: darken($ultra-light-danger, 10%) !important;

			&:where(.cal-today, .is-today) {
				/* stylelint-disable-next-line declaration-no-important */
				background-color: $danger !important;
			}
		}
	}

	// If nothing is wrong we set the background to success
	&:not(:has(.leave-day-grid fa-icon.text-danger)) {
		background-color: $ultra-light-success;

		.day-header-content,
		p-cell-top {
			/* stylelint-disable-next-line declaration-no-important */
			background-color: darken($ultra-light-success, 10%) !important;

			&:where(.cal-today, .is-today) {
				/* stylelint-disable-next-line declaration-no-important */
				background-color: $success !important;
			}
		}
	}
}
