/* Making the divider not show up if the group it belongs to is the last child in the dropdown item group. */
p-dropdown-item-group:not([hidden], :has(~ :where(p-dropdown-item-group, p-dropdown-item):not([hidden]))) {
	.dropdown-group-divider {
		display: none;
	}
}

p-dropdown .items-and-groups p-dropdown-item button.btn {
	/* stylelint-disable-next-line declaration-no-important */
	border-radius: 0 !important;
}

// Remove? PLANO-186582
.step-card p-dropdown *:not(p-badge) {
	min-width: 0;
}

p-dropdown:not(.dropdown-touched) button.has-danger {
	border-color: $gray-400;
	border-bottom-color: $danger;
}
