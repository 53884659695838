@import "variables";

/*
 * Calculate the radius that fits exactly into the border-radius.
 * If you would just put a box with $border-radius into another box with the same radius,
 * the inner boxes corners would not touch the outer boxes corners. You would have a little gap there.
 * Thus i use a smaller radius for the inner box.
 * See https://www.30secondsofcode.org/css/s/nested-border-radius/
 */
$border-inner-radius: calc($border-radius / 3 * 2);

/*
 * List items don't have overflow hidden to make them work with badges.
 * This is a workaround to make them work with buttons on the right.
 * Without this, the button background would overflow the list item.
 */
.list-group-item:first-of-type:not(.rounded-start-0, .rounded-0) {
	> {
		:first-child .btn {
			border-top-left-radius: $border-inner-radius;
		}
		:last-child .btn {
			border-top-right-radius: $border-inner-radius;
		}
	}
}
.list-group-item:last-of-type:not(.rounded-end-0, .rounded-0) {
	> {
		:first-child .btn {
			border-bottom-left-radius: $border-inner-radius;
		}
		:last-child .btn {
			border-bottom-right-radius: $border-inner-radius;
		}
	}
}
