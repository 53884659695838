@import "bootstrap/scss/mixins/buttons";

/*
 * Custom button variants of the mixins that bootstrap provides.
 *
 * These mixins all solve the issue, that on some mobile devices hover gets triggered, when a button has been touched.
 */

@mixin p-button-variant(
	$background,
	$border,
	$color,
	$hover-background: darken($background, 7.5%),
	$hover-border: darken($background, 10%),
	$hover-color: color-contrast($hover-background),
	$active-background: darken($background, 10%),
	$active-border: darken($active-background, 12.5%),
	$active-color: color-contrast($active-background),
	$disabled-background,
	$disabled-color
) {
	@include button-variant(
		$background: $background,
		$border: $border,
		$color: $color,
		$hover-background: $hover-background,
		$hover-border: $hover-border,
		$hover-color: $hover-color,
		$active-background: $active-background,
		$active-border: $active-background,
		$active-color: $active-color,
		$disabled-background: $disabled-background,
		$disabled-color: $disabled-color
	);
	@media (hover: none) {
		&:hover {
			@include button-variant(
				$background: $background,
				$border: $border,
				$color: $color,
				$hover-background: $background,
				$hover-border: $border,
				$hover-color: $color,
				$active-background: $active-background,
				$active-border: $active-border,
				$active-color: $active-color,
				$disabled-background: $disabled-background,
				$disabled-color: $disabled-color
			);
		}
	}
}

@mixin p-button-outline-variant(
	$color,
	$color-hover: color-contrast($color),
	$active-background: $color,
	$active-border: $color,
	$active-color: color-contrast($active-background)
) {
	@include button-outline-variant(
		$color: $color,
		$color-hover: $color-hover,
		$active-background: $active-background,
		$active-border: $active-border,
		$active-color: $active-color
	);
	@media (hover: none) {
		&:hover {
			@include button-outline-variant(
				$color: $color,
				$color-hover: $color,
				$active-background: $active-background,
				$active-border: $color,
				$active-color: $color
			);

			// Usually we just include different values for the mixins for devices that don't support hover.
			// But here it is not enough, because Bootstraps mixin button-outline-variant does not provide params for e.g. the
			// background color.
			&:not(.active) {
				background-color: transparent;
			}

			&.active {
				@include button-outline-variant(
					$color: $color,
					$color-hover: $color-hover,
					$active-background: $active-background,
					$active-border: $color,
					$active-color: $color-hover
				);
			}

			// Hover and active styles for active buttons need to be inverted on outline buttons.
			&:not(:disabled, .disabled) {
				&.active,
				&:active {
					--bs-btn-hover-bg: #{$active-background};
				}
			}
		}
	}
}

/*
 * On some mobile devices hover gets triggered, when a button has been touched. This is an issue for toggle buttons,
 * because Bootstrap only defines one hover style, no matter if the button is active or not. This means that the hover
 * style usually looks close to the active style and if not this, then close to the default style.
 * The result would be:
 * - If the button is not active and not hovered everything looks fine.
 * - If the button has been tapped, it has the active styles, which is good.
 * - If the user taps it again, it stays in the hover state which usually can be easily confused with the active state.
 * For that reason we need to 'turn off' the hover styles for toggle buttons.
 * As Bootstrap always sets hover styles we have to use the same values on hover.
 */
@mixin p-toggle-button-variant(
	$background,
	$border,
	$hover-background: darken($background, 7.5%),
	$hover-border: darken($border, 10%),
	$hover-color: color-contrast($hover-background),
	$active-background: darken($background, 10%),
	$active-border: darken($border, 12.5%),
	$active-color: color-contrast($active-background)
) {
	@include box-shadow($btn-box-shadow);
	@include button-variant(
		$background,
		$border,
		$hover-background: $hover-background,
		$hover-border: $hover-border,
		$hover-color: $hover-color,
		$active-background: $active-background,
		$active-border: $active-border,
		$active-color: $active-color,
		$disabled-color: color-contrast($background),
		$disabled-background: $background,
		$disabled-border: $border
	);

	@media (hover: none) {
		&:hover {
			// Here we set the hover and active styles to the same styles as the default styles.
			@include button-variant(
				$background: $background,
				$border: $border,
				$color: color-contrast($background),
				$hover-background: $background,
				$hover-border: $border,
				$hover-color: color-contrast($background),
				$active-background: $background,
				$active-border: $border,
				$active-color: color-contrast($background),
				$disabled-color: color-contrast($background),
				$disabled-background: $background,
				$disabled-border: $border
			);

			// Hover and active styles for active buttons need to be inverted on toggle buttons.
			&.active,
			&:active {
				@include button-variant(
					$background: $active-background,
					$border: $active-border,
					$color: $active-color,
					$hover-background: $active-background,
					$hover-border: $active-border,
					$hover-color: $active-color,
					$active-background: $active-background,
					$active-border: $active-border,
					$active-color: $active-color,
					$disabled-color: color-contrast($background),
					$disabled-background: $background,
					$disabled-border: $border
				);
			}
		}
	}

	&:focus-visible,
	&.focus {
		@if $enable-shadows {
			@include box-shadow(
				$btn-box-shadow,
				0 0 0 $btn-focus-width rgba(mix(color-contrast($background), $border, 15%), 0.5)
			);
		} @else {
			// Avoid using mixin so we can pass custom focus shadow properly
			box-shadow: 0 0 0 $btn-focus-width rgba(mix(color-contrast($background), $border, 15%), 0.5);
		}
	}

	// Disabled comes first so active can properly restyle
	&.disabled,
	&:disabled {
		// stylelint-disable-next-line declaration-no-important
		color: color-contrast($background) !important;
		// stylelint-disable-next-line declaration-no-important
		background-color: $background !important;
		// stylelint-disable-next-line declaration-no-important
		border-color: $border !important;
		// Remove CSS gradients if they're enabled
		@if $enable-gradients {
			background-image: none;
		}
	}

	&:not(:disabled, .disabled):active {
		background-color: darken($active-background, 10%);
		@if $enable-gradients {
			background-image: none; // Remove the gradient for the pressed/active state
		}
		border-color: darken($active-background, 10%);

		&:focus-visible {
			@if $enable-shadows and $btn-active-box-shadow != none {
				@include box-shadow(
					$btn-active-box-shadow,
					0 0 0 $btn-focus-width rgba(mix(color-contrast($background), $border, 15%), 0.5)
				);
			} @else {
				// Avoid using mixin so we can pass custom focus shadow properly
				box-shadow: 0 0 0 $btn-focus-width rgba(mix(color-contrast($background), $border, 15%), 0.5);
			}
		}
	}

	.show > &.dropdown-toggle {
		background-color: $active-background;
		@if $enable-gradients {
			background-image: none; // Remove the gradient for the pressed/active state
		}
		border-color: $active-border;

		&:focus-visible {
			@if $enable-shadows and $btn-active-box-shadow != none {
				@include box-shadow(
					$btn-active-box-shadow,
					0 0 0 $btn-focus-width rgba(mix(color-contrast($background), $border, 15%), 0.5)
				);
			} @else {
				// Avoid using mixin so we can pass custom focus shadow properly
				box-shadow: 0 0 0 $btn-focus-width rgba(mix(color-contrast($background), $border, 15%), 0.5);
			}
		}
	}
}
