@use "sass:color";
@import "./bootstrap-variables";

/**
 * These are variables that get used in multiple areas of our application.
 *
 * If you write a new variable that only gets used in one single file, please consider moving the
 * variable definition to that file.
 * Please also check if you find existing variables that serve your purpose, or extend bootstrap scss.
 * More infos about extending bootstrap through scss variables: https://getbootstrap.com/docs/5.0/customize/sass/
 */

$disabled-opacity: 0.6;
$muted-opacity: 0.7;
$muted-item-opacity: 0.2;

$p-sidebar-width: 330px;

$navbar-padding-x: 1rem;

$alert-padding-x: 1em;
$alert-padding-y: 0.75em;

$badge-padding-x: 0.3em;
$badge-padding-y: 0.25em;

/* Gray colors for things that are not clickable */
$gray-cold-100: hsl(210, 15%, 97.5%);
$gray-cold-150: hsl(210, 30%, 97.5%);
$gray-cold-200: hsl(210, 13%, 86%);
$gray-cold-300: hsl(210, 15%, 73%);
$gray-cold-400: hsl(210, 16%, 62%);
$gray-cold-500: hsl(210, 18%, 46%);
$gray-cold-600: hsl(210, 18%, 40%);
$gray-cold-700: hsl(210, 18%, 33%);
$gray-cold-800: hsl(210, 18%, 26%);
$gray-cold-900: hsl(210, 19%, 18%);

$ultra-light-danger: hsl(353, 69%, 97%);
$ultra-light-success: hsl(120, 33%, 97%);
$ultra-light-purple: hsl(262, 100%, 98%);
$ultra-light-warning: hsl(46, 100%, 97%);

$black: #000;

// TODO: Reduce amount of sass vars
$lighten-09: rgba(255, 255, 255, 0.9);
$lighten-05: rgba(255, 255, 255, 0.5);
$lighten-025: rgba(255, 255, 255, 0.25);
$lighten-015: rgba(255, 255, 255, 0.15);
$lighten-010: rgba(255, 255, 255, 0.1);
$lighten-005: rgba(255, 255, 255, 0.05);

// TODO: Reduce amount of sass vars
$darken-05: rgba(0, 0, 0, 0.5);
$darken-04: rgba(0, 0, 0, 0.4);
$darken-03: rgba(0, 0, 0, 0.3);
$darken-02: rgba(0, 0, 0, 0.2);
$darken-015: rgba(0, 0, 0, 0.15);
$darken-0125: rgba(5, 10, 26, 0.12);
$darken-005: rgba(0, 0, 0, 0.05);
$darken-003: rgba(0, 0, 0, 0.03);

// *** Z-INDEX LIST ***
$z-index-color-marker: 10;
$z-index-p-sidebar: 2000000004;
$z-index-area-blocking-spinner: 12;
// z-index: 1020; .sticky-top (e.g. used by p-subheader)

// Bootstrap START
// $zindex-dropdown:                   1000 !default;
// $zindex-sticky:                     1020 !default;
// $zindex-fixed:                      1030 !default;
// $zindex-modal-backdrop:             1040 !default;
// $zindex-modal:                      1050 !default;
// $zindex-popover:                    1060 !default;
// $zindex-tooltip:                    1070 !default;
// Bootstrap END

$z-index-focussed-item: 1020;
$z-index-above-focussed-item: 1021;
$z-index-day-header: 1021;
// float-ui-content has zindex: 1021
$z-index-p-menu: 1000000000;
$z-index-tawk: 2000000001;
$z-index-modal: 2000000002;
$z-index-sticky: 2000000003;
$z-index-p-client-header: 2000000004;
$z-index-toast-container: 2000000005;
$z-index-dropdown-content: 2000000006;
$z-index-tooltip: 2000000007;
$z-index-cookie-banner: 2000000008;

// With this value the alignment with icons (e.g. on checkboxes and radios) works best.
// The reason is that the browser does 'pixel snapping' when positioning the text and icon in the ui.
$form-control-min-height-pixel-snapping-perfection: 0.03rem;

$form-control-min-height: calc(2.5rem + $form-control-min-height-pixel-snapping-perfection);

$p-navbar-height: 55px;

$p-angular-editor-min-height: 80px;

$p-tabs-options-height: 49px;

$p-color-marker-width: 7px;

@mixin pulse-shadow($toColor) {
	box-shadow:
		0 0 0 0 $gray-100,
		0 0 0 0 $toColor;
}

// TODO: Check if obsolete
$p-assignment-process-type-caption-card-open:
	0 1px 3px rgba(0, 0, 0, 0.12),
	0 1px 2px rgba(0, 0, 0, 0.24);

// TODO: deprecated
$lightBorder: $lighten-025;

/* We want to overwrite Bootstrap’s variable $font-size-base with a clamp function, but that's impossible because of calculations inside the Bootstrap code that use $font-size-base. You can not calculate like clamp(…) * 1.25. You'd need to wrap it with calc(…). Bootstrap will probably do that in their V6 🤞 Until then, we have introduced $font-size-body that should be used in our code instead of $font-size-base. */
// TODO: PLANO-184835
$font-size-body: clamp(
	0.9375rem,
	0.9189rem + 0.093vw,
	1rem
); // Usually will be 16px. ☝️ If you change this value, adjust the declaration in _bootstrap-variables.scss as well.

$font-size-xxs: calc($font-size-body * 0.6); // `9.6px` if browser is on `16px`
$font-size-xs: calc($font-size-body * 0.75); // `12px` if browser is on `16px`

// We've introduced $font-size-lg-clamp to use clamp for the font-size. Ideally we could overwrite Bootstrap's variable $font-size-lg, but that's not possible due to bootstrap's internal calculations.
// TODO: PLANO-184834
$font-size-lg-clamp: calc($font-size-body * 1.25); // Typically `20px` if browser has `16px`.
$font-size-xxl: calc($font-size-body * 1.875); // `30px` if browser is on `16px`

$primary-text: darken($primary, 10%);

$bg-skeleton: $gray-300;

$member-badge-size-small: 1.5rem;
$member-badge-size-normal: 2rem;

$badge-size-xl: 148px;

$p-emoji-width: 18px;

/**
 * Since some PDFs are quite large in size, they require a larger area to be rendered.
 * To handle this, a bigger area is used for rendering the pdf itself, and then we scale
 * it to the desired size, preventing rendering issues, like white horizontal lines.
 */
$pdf-render-width: 1000px;

$calendar-day-header-min-height: 35.325px; // This matches the height of the checkbox with size MD we show in different situations on the header.
