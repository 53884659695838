@import "variables";

ngb-modal-window:not(.no-gutter-body) {
	.modal-body {
		scrollbar-gutter: stable;
		overflow-x: hidden;
	}
}

$p-dr-plano-scrollbar-shadow: inset 0 0 6px $darken-03;
$p-dr-plano-scrollbar-width: 12px;

/* stylelint-disable-next-line no-descending-specificity */
.p-scroll-parent,
.modal-body {
	scroll-padding-top: $p-navbar-height * 1.2;
}

.hide-scrollbar .content {
	/* stylelint-disable-next-line plugin/no-unsupported-browser-features */
	scrollbar-width: none;
	&::-webkit-scrollbar {
		display: none;
	}
}

// Need to have a bigger scroll padding top on the access control page
// due to the sticky header of the tables
p-permission-settings .p-scroll-parent {
	/* stylelint-disable-next-line sh-waqar/declaration-use-variable */
	scroll-padding-top: ($p-navbar-height * 1.2) + 150px;
}

.is-desktop {
	& ::-webkit-scrollbar-thumb {
		border-radius: 4px; /* stylelint-disable-line declaration-property-unit-disallowed-list */
		box-shadow: $p-dr-plano-scrollbar-shadow;
		background-color: $primary;
	}

	& ::-webkit-scrollbar {
		// NOTE: If you change this, please check ignore-gutter.directive.ts and change the widthOfColumn variable
		// accordingly, also check the ignore-gutter class
		width: $p-dr-plano-scrollbar-width;
		background-color: transparent;
	}

	& ::-webkit-scrollbar-track {
		box-shadow: $p-dr-plano-scrollbar-shadow;
		border-radius: $border-radius-xl;
		background-color: transparent;
	}
}

/*
    Class to be used when an element is inside a parent with scrollbar-gutter:stable, but
    the gutter should be ignored. z.B. When an element has a background color and width of 100%,
    and so, no white gap should be visible
*/
.ignore-gutter,
.p-tooltip-show-more-modal p-modal-content-body > * {
	position: relative;
	&::after {
		position: absolute;
		top: 0;
		right: -15px;
		bottom: 0;
		display: block;
		width: 15px;
		content: "";
		background-color: inherit;
	}
}

.shadows-chevrons-tabs-wrapper.position-sticky.is-full-width {
	position: relative;
	.overlay-scrollbar-space {
		position: absolute;
		right: -15px;
		bottom: 0;
		display: block;
		width: 15px;
		height: $border-width;
		content: "";
		background-color: $gray-400;
	}
}
