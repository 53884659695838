p-work-rule-versions .list-item-value:not(.ruleAndUserMatches) {
	* {
		/* stylelint-disable-next-line declaration-no-important -- want to show all elements with the normal color */
		color: var(--#{$prefix}list-group-color) !important;
	}
}

.list {
	.list-headline .list-headline-item,
	.list-content .list-item .list-item-value {
		&.activePeriod {
			flex: 3;
			flex-grow: 0;
			flex-basis: 240px;
		}

		&.checkScope {
			flex: 2;
		}

		&.checkElement {
			flex: 3;
		}

		&.ruleAndUserMatches {
			flex: 3;
		}

		&.checkValue {
			flex: 2;
		}

		// TODO: PLANO-186154 here are examples how to make the lists more responsive. Implement the responsiveness.
		@container (inline-size < 1200px) {
			&.useAnAccount {
				display: none;
			}
		}

		// TODO: PLANO-186154 here are examples how to make the lists more responsive. Implement the responsiveness.
		@container (inline-size < 1020px) {
			&.ruleAndUserMatches {
				display: none;
			}
		}
	}
}
