@use "sass:color";
@import "variables";
@import "bootstrap/scss/mixins";

/* We would like to set $font-size-base to use clamp, but thats not possible because of calculations inside bootstrap code that uses $font-size-base. You can not calculate like `clamp(…) * 1.25`. You'd need to wrap it with calc(…). Thus, we overwrite the body ourself instead of relying on bootstrap. We have introduced $font-size-body (see _non-bootstrap-variables.scss) that should be used in our own code instead of $font-size-base. We also overwrite Bootstrap's custom property to ensure that we catch all the cases in Bootstrap's internal code. */

body {
	--bs-body-font-size: #{$font-size-body};
	font-size: $font-size-body;
}

p,
h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6,
a,
button,
label,
span {
	-webkit-line-clamp: 3;
	&.dotted-line {
		$line-width: 6px;
		width: $line-width;
		height: 2px;
		display: inline-block;
		vertical-align: middle;
		margin-right: $line-width * 0.5;
		background-color: $primary;
	}
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
	word-break: break-word;
}

th {
	vertical-align: middle;
}

h1,
.h1,
h2,
.h2,
h3,
.h3 {
	line-height: 1.3;
}
h4,
.h4 {
	line-height: 1.45;
}
h5,
.h5,
h6,
.h6,
p,
small {
	line-height: 1.55;
}

/* font weights */
h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
	font-weight: $font-weight-bold;
}

.container h2,
.container-xs h2,
.container-sm h2,
.container-md h2,
.container-lg h2,
.container-xl h2,
.container-xxl h2 {
	// If we use a image, like the logo of a company as a headline, then the image itself should not add any margin.
	img {
		margin: 0 auto;
	}
}

p,
small {
	font-weight: $font-weight-normal;
}

strong {
	font-weight: $font-weight-semibold;
}

label {
	font-size: $form-label-font-size;
	font-weight: $form-label-font-weight;
	cursor: inherit;
	text-wrap: balance;

	&.read-mode {
		text-transform: none;
	}
}

// As long as $dt-font-weight has default value $font-weight-bold, it needs the opacity style here to be in harmony with the visual weight of headlines.
dt,
// As long as $font-weight-bold has value 700, it needs the opacity style here to be in harmony with the visual weight of headlines.
.fw-bold {
	opacity: 0.85;
}

/* margins */
h1,
.h1 {
	/* stylelint-disable-next-line sh-waqar/declaration-use-variable */
	margin-bottom: 1.4em;
}
/* stylelint-disable-next-line no-descending-specificity */
h2,
.h2 {
	/* stylelint-disable-next-line sh-waqar/declaration-use-variable */
	margin-bottom: 1.2em;
}
h3,
.h3 {
	/* stylelint-disable-next-line sh-waqar/declaration-use-variable */
	margin-bottom: 0.8em;
}
h4,
.h4,
h5,
.h5,
h6,
.h6,
p {
	/* stylelint-disable-next-line sh-waqar/declaration-use-variable */
	margin-bottom: 0.5em;
}

/* nicer text wrap for headline items */
:where(h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6) {
	text-wrap: balance;
}

* {
	/* Add a small gap between the underline and the text (only applied using text-decoration). */
	text-underline-offset: 0.15em;
	/* For better alignment, punctuation marks hang at the start or end of a line of text. */
	/* stylelint-disable-next-line plugin/no-unsupported-browser-features */
	hanging-punctuation: first last;
}

small {
	font-weight: $font-weight-normal;
}
mark {
	box-decoration-break: clone;
	color: $mark-color; // helps to keep the color if used inside, e.g., buttons
	max-width: fit-content;
}

$code-and-pre-bg: $darken-005;

pre {
	white-space: pre;
	/* stylelint-disable-next-line sh-waqar/declaration-use-variable */ /* Paddings here should be based on the elements font-size. Therefore, using em is better than rem. However, we don't have any variables now with em values. */
	padding: 0.7em 1em;
	border-radius: $border-radius;
	background-color: $code-and-pre-bg;

	@include media-breakpoint-down(lg) {
		white-space: pre-wrap;
	}

	& > * {
		white-space: pre;
	}
}

code {
	/* stylelint-disable-next-line sh-waqar/declaration-use-variable */ /* As code elements are often in context of normal text, their paddings should be very small to look good. So, we need very specific values here. Having tiny paddings on text, makes it also necessary to have different values for top and bottom to take the line height into consideration. */
	padding: 0.15em 0.35em 0.2em 0.35em;
	/* As the paddings are so tiny for this version, the normal border-radius (using our variables) with an rem value doesn't look good here. Instead, we need a more flexible value with em. */
	border-radius: 0.2em;
	user-select: text;
	background-color: $code-and-pre-bg;
	box-decoration-break: clone;
	max-width: fit-content;
}

blockquote {
	font-style: italic;
	margin-bottom: 0;
	word-break: break-word;
}

ol,
ul {
	padding-left: map-get($spacers, 4);

	li {
		padding-left: map-get($spacers, 3);
	}

	&:not(.container).list-unstyled,
	&.list-group-flush {
		padding-left: 0;

		/* stylelint-disable-next-line no-descending-specificity */
		li {
			padding-left: 0;
		}
	}
}

/* "touch-action: manipulation;" improves the UX on touch devices for clickable elements – but should not be applied to scrollable elements (e.g. div, body, etc.). It disables non-standard gestures such as double-tap to zoom. Disabling double-tap to zoom removes the need for browsers to delay the generation of click events when the user taps the screen. More about it: https://developer.mozilla.org/en-US/docs/Web/CSS/touch-action#manipulation */
a,
.link,
button,
.btn,
label,
.clickable {
	/* stylelint-disable-next-line plugin/no-unsupported-browser-features */
	touch-action: manipulation;
}

/* With .btn, the <a> tags get already focus-visible styles. */
a,
.link {
	&:not(.btn):focus-visible {
		/* stylelint-disable-next-line declaration-no-important */
		outline: auto !important;
	}
}

@each $color, $value in $theme-colors {
	.link-#{$color} {
		cursor: pointer;
		/* stylelint-disable-next-line declaration-no-important */
		color: $value !important;

		@media (hover: hover) {
			transition: color 0.3s ease-out;

			&:hover {
				/* stylelint-disable-next-line declaration-no-important */
				color: shift-color-level($value, 3) !important;
			}
		}
	}
}

.form-text {
	opacity: 0.85;
}

.nowrap {
	// stylelint-disable-next-line declaration-no-important
	white-space: nowrap !important;
}

.word-break {
	word-break: break-word;
	hyphens: auto;
}

.wrap {
	// stylelint-disable-next-line declaration-no-important
	white-space: initial !important;
}

.text-line-through {
	text-decoration: line-through;
}
