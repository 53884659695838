@import "variables";

[aria-labelledby="entire-leave-label"] p-tabs .position-sticky {
	background-color: $gray-cold-100;
	li {
		/* stylelint-disable-next-line declaration-no-important */
		margin-top: 0 !important;
	}
	border-top-left-radius: $border-radius-lg;
	border-top-right-radius: $border-radius-lg;
}

.p-tooltip-show-more-modal:has(.bg-all-day-item) {
	.bg-all-day-item {
		width: 100%;
	}
	.modal-header {
		padding: 0;
	}
}
