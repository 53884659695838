// TODO: PLANO-186041 adjust selector
// Ensure buttons don't have the usual min-height for buttons inside a form
.holiday-items-top.sticky-top p-button .btn {
	min-height: 0;
}

.holiday-items-modal .modal-body {
	/* stylelint-disable-next-line sh-waqar/declaration-use-variable */
	scroll-padding-top: 250px;
}
