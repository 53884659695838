fa-icon {
	flex-shrink: 0;
	display: inline-block;

	// Fix cases where fa-icon had a underline on hover when used inside a <a class="btn…">
	a & {
		&::before,
		&::after {
			/* stylelint-disable-next-line declaration-no-important */
			text-decoration: none !important;

			/* this removes any text-decoration:underline if the icon is used in  an <a> tag with text-decoration on it */
			display: inline-block;
		}
	}

	&:has(> span.fa-li) {
		// When the fa-icon element was `display: inline`, it got a line-height applied.
		// Now it is `display: inline-block` instead, so we need to adjust the height.
		height: 1.125em;
	}

	&.tabs-navigator {
		span {
			opacity: 0.5;
			transition: opacity 0.2s;
		}
		@media (hover: hover) {
			&:hover {
				span {
					opacity: 1;
				}
			}
		}
	}
}

@mixin icon-rotation-styles {
	fa-icon:not(.block-rotation) {
		.fa-close,
		.fa-times,
		.fa-xmark {
			transform: rotate(90deg);
		}
	}
}

// cSpell:ignore xmark
button:not(:disabled) {
	fa-icon:not(.block-rotation) {
		.fa-close,
		.fa-times,
		.fa-xmark {
			transition: transform 0.3s ease-in-out;
			transform: rotate(0);
		}
	}
	&:focus {
		@include icon-rotation-styles;
	}
	@media (hover: hover) {
		&:hover {
			@include icon-rotation-styles;
		}
	}
}
