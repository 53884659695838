a:hover:has(.btn) {
	text-decoration: none;
}

// This is necessary because of the existence of .ng-content-wrapper. So please read the docs of .ng-content-wrapper
// to understand why this is necessary.
// TODO: PLANO-186041 Remove the usage of the ng-content-wrapper class if possible.
.ng-content-wrapper {
	display: flex;

	// ng-content might be empty. If thats the case, we need to hide it to not make e.g. gaps appear.
	// TODO: PLANO-186041 Remove this if possible.
	&:empty {
		display: none;
	}
}
