@import "bootstrap/scss/mixins/border-radius";

.btn-group {
	/*
	 * Bootstrap has the concept of .input-group with .input-group-text. You can use it like this:
	 * <div class="input-group">
	 *   <input type="text" class="form-control">
	 *   <div class="input-group-text">some text or icon</div>
	 * </div>
	 *
	 * Bootstrap does not provide the same to .btn-group. These styles add support for this concept to .btn-group.
	 * You can use it like this:
	 * <div class="btn-group">
	 *   <div class="btn-group-append">some text or icon</div>
	 *   <button class="btn btn-primary">Button 1</button>
	 *   <div class="btn-group-prepend">some text or icon</div>
	 * </div>
	 */
	> .btn-group-prepend,
	> .btn-group-append {
		flex-grow: 0;
		display: flex;

		&:not(:first-child) {
			margin-left: calc(var(--bs-border-width) * -1);
		}

		&:first-child > .btn {
			@include border-end-radius(0);
			&:not(:first-child) {
				@include border-start-radius(0);
			}
		}

		/* stylelint-disable-next-line no-descending-specificity -- not possible to solve */
		&:last-child > .btn {
			@include border-start-radius(0);
			&:not(:last-child) {
				@include border-end-radius(0);
			}
		}
	}
}
