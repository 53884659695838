p-threshold-card .threshold-card > * {
	height: 100%;
}

p-threshold-card .input-group {
	.whole-input-wrapper {
		/* stylelint-disable-next-line declaration-no-important */
		min-width: 0 !important;
		/* stylelint-disable-next-line declaration-no-important */
		flex-wrap: nowrap !important;
	}

	max-width: 175px;
}
