[dropdown] {
	position: relative;

	&.open.show {
		.dropdown-menu {
			min-width: 100%;
			position: absolute;
			display: block;
		}
	}
}

/* stylelint-disable-next-line no-descending-specificity */
.dropdown-menu {
	&.dropdown-normal {
		min-width: 100%;
		top: 100%;
	}
	margin: 0;
	margin-bottom: map-get($spacers, 5);
	width: max-content;
	/* stylelint-disable-next-line no-descending-specificity */
	&:not(ngb-typeahead-window) {
		max-width: min(400px, 90vw);
	}
	padding: 0;
	box-shadow: $dropdown-box-shadow;
	z-index: $z-index-dropdown-content;
	max-height: 60vh;
	// stylelint-disable-next-line declaration-block-no-duplicate-properties
	max-height: 60svh;
	overflow-x: auto;

	&.dropdown-content-right {
		right: 0;
		left: auto;
	}

	li {
		padding-left: 0;
	}

	&::-webkit-scrollbar {
		/* stylelint-disable-next-line declaration-no-important -- override set background-color for scrollbar */
		background-color: $white !important;
	}
}

bs-dropdown-container {
	// stylelint-disable-next-line declaration-no-important
	position: static !important;
}
