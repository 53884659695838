.container,
.container-xs,
.container-sm,
.container-md,
.container-lg,
.container-xl,
.container-xxl {
	// TODO: PLANO-185277 Add this
	// padding-top: map-get($spacers, 5);

	h2 {
		// TODO: PLANO-185277 Remove this
		/* stylelint-disable-next-line sh-waqar/declaration-use-variable */
		margin-top: 2.5rem;
	}
}

@media (min-width: 1200px) {
	.container,
	.container-xs,
	.container-sm,
	.container-md,
	.container-lg {
		max-width: 1200px;
	}
}
