/**
 * This file contains utility classes, most likely written in the pattern of bootstrap classes.
 *
 * If possible, extend bootstrap utility classes via ./bootstrap/utilities.scss instead.
 *
 * It should always be avoided to add new classes here. Please check if you find existing classes
 * that serve your purpose, or extend bootstrap scss.
 * More infos here: https://getbootstrap.com/docs/5.0/customize/sass/
 */

@import "bootstrap/scss/mixins/breakpoints";

.crop-on-overflow {
	overflow: hidden;
	text-overflow: ellipsis;
	&:not(.max-lines-limit-2) {
		white-space: nowrap;
	}
}

.p-overflow-ellipsis {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.font-tabular-nums {
	font-variant-numeric: tabular-nums;
}

/* For better visualization of fractions, the numerator and denominator are made smaller and separated by a slash. This class should only be applied to very short strings where "nowrap" is reasonable. */
.font-diagonal-fractions {
	font-variant-numeric: diagonal-fractions;
	white-space: nowrap;
}

.mt--1 {
	/* stylelint-disable-next-line sh-waqar/declaration-use-variable */
	margin-top: -1px;
}

.w-md-fit-content {
	@include media-breakpoint-up(md) {
		/* stylelint-disable-next-line declaration-no-important */
		width: fit-content !important;
	}
}

.w-md-50 {
	@include media-breakpoint-up(md) {
		/* stylelint-disable-next-line declaration-no-important */
		width: 50% !important;
	}
}

.max-w-60-ch {
	max-width: 60ch;
}

.max-w-50-ch {
	max-width: 50ch;
}

.max-w-40-ch {
	max-width: 40ch;
}

.max-w-30-ch {
	max-width: 30ch;
}

.max-w-none {
	// stylelint-disable-next-line declaration-no-important
	max-width: none !important;
}

.max-w-fit-content {
	max-width: fit-content;
}

.rounded-1-start {
	/* stylelint-disable declaration-no-important */
	border-top-left-radius: var(--bs-border-radius-sm) !important;
	border-bottom-left-radius: var(--bs-border-radius-sm) !important;
	border-top-right-radius: 0 !important;
	border-bottom-right-radius: 0 !important;
	/* stylelint-enable declaration-no-important */
}

.rounded-1-end {
	/* stylelint-disable declaration-no-important */
	border-top-right-radius: var(--bs-border-radius-sm) !important;
	border-bottom-right-radius: var(--bs-border-radius-sm) !important;
	border-top-left-radius: 0 !important;
	border-bottom-left-radius: 0 !important;
	/* stylelint-enable declaration-no-important */
}

.rounded-bottom-end {
	/* stylelint-disable declaration-no-important */
	border-bottom-right-radius: var(--bs-border-radius-sm) !important;
	/* stylelint-enable declaration-no-important */
}

.min-h-0 {
	/* stylelint-disable-next-line declaration-no-important */
	min-height: 0 !important;
}

.max-w-min-content {
	/* stylelint-disable-next-line declaration-no-important */
	max-width: min-content !important;
}

// Link styles. Use it when you want to imitate a link on a tag thats not a <a> tag.
.link {
	cursor: pointer;
	color: $link-color;
	text-decoration: $link-decoration;
	&:hover {
		color: $link-hover-color;
		text-decoration: $link-hover-decoration;
	}
}

.grid-cols-2 {
	grid-template-columns: 1fr 1fr;
}

.grid-cols-1 {
	grid-template-columns: 1fr;
}

.dashed-border-bottom {
	position: relative;
	&::before {
		position: absolute;
		pointer-events: none;
		top: 0;
		// The calculation for the bottom value: We take half of the border-bottom-width value below times -1 to make the border span two sections.
		bottom: calc($border-width * 3.5 / 2 * -1);
		left: 0;
		z-index: $z-index-sticky;
		display: block;
		width: 4000px;
		content: "";
		border-width: 0;
		border-bottom-width: $border-width * 3.5;
		border-style: solid;
		border-image-repeat: repeat;
		border-image-slice: 1000;
		border-image-source: linear-gradient(to right, white 55%, rgba(255, 255, 255, 0) 0%);
	}

	p-work-rule-filters & {
		&::before {
			/* stylelint-disable-next-line declaration-no-important */
			width: 3000px !important;
		}
	}
}

.nowrap-content * {
	/* stylelint-disable-next-line declaration-no-important */
	white-space: nowrap !important;
}

.bg-ultra-light-danger {
	background-color: $ultra-light-danger;
}

.bg-ultra-light-success {
	background-color: $ultra-light-success;
}

.bg-ultra-light-purple {
	background-color: $ultra-light-purple;
}

.bg-ultra-light-warning {
	background-color: $ultra-light-warning;
}
