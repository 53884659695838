p-wizard-step {
	// Position relative is needed for the line below connecting the steps
	position: relative;

	// Line connecting the steps
	&:not(.p-wizard-step-1)::before {
		position: absolute;
		top: map-get($spacers, 3) * -1;
		height: map-get($spacers, 3);
		left: 26px;
		content: "";
		width: 3px;
		z-index: -1;
		background-color: $card-border-color;
	}
}

/* As the step's card already has padding on it, we set the margin of the last <p> to 0 for consistent spacing. */
.card > p:last-child {
	margin-bottom: 0;
}

p-wizard {
	// Use this variable to ensure the same duration here and in p-wizard-step.component.scss
	--p-transition-duration: 0.3s;
	svg {
		pointer-events: none;
		path {
			fill: none;
			stroke: $purple;
			stroke-width: 1px;
			transition: opacity 75ms linear;
		}
	}

	.step-card {
		transition: opacity var(--p-transition-duration) ease-in-out;
	}

	&.is-selecting-step {
		svg path {
			transition: var(--p-transition-duration) linear;
		}
		.step-card {
			opacity: 0;
		}
	}

	&:not(.is-selecting-step) {
		.step-card {
			opacity: 1;
		}
	}

	&.redraw-line svg path {
		stroke-dasharray: 1000;
		stroke-dashoffset: 1000;
		animation: p-dash 1.5s linear forwards;
	}

	& .card-body :not(p-work-rule-filters) p-section:first-child {
		/* stylelint-disable-next-line declaration-no-important */
		padding-top: 0 !important;
	}

	p-scroll-box {
		/* stylelint-disable-next-line declaration-no-important */
		max-height: none !important;
	}

	&:has(.step-card.border-danger) {
		svg path {
			stroke: $danger;
		}
	}
}

@keyframes p-dash {
	to {
		stroke-dashoffset: 0;
	}
}

p .use-body-color {
	/* stylelint-disable-next-line declaration-no-important */
	color: $body-color !important;
	fa-icon,
	mark {
		/* stylelint-disable-next-line declaration-no-important */
		color: $body-color !important;
	}
}
