@import "mixins/buttons";
@import "../mixins/colorful-bg";

.btn {
	fa-icon.fa-default-size {
		/* stylelint-disable-next-line sh-waqar/declaration-use-variable */
		font-size: 0.95em;
	}

	// TODO: PLANO-186041 After dom elements have been reduced, it should not happen anymore that there is a .card-option inside a .btn. So you can remove the next line.
	// TODO: PLANO-187884 Decide how to continue with .card-options
	// TODO: PLANO-187885 Possibly get rid of many button borders. The following block will most likely be obsolete then.
	@each $color, $value in $theme-colors {
		@if $color == "secondary" {
			&.btn-#{$color} {
				.card-option,
				&.card-option {
					border-color: $border-color;
				}
			}
		}

		&.btn-outline-#{$color} {
			.card-option,
			&.card-option {
				border-color: $border-color;
			}
		}
	}

	&.disabled {
		cursor: not-allowed;
	}
}

@each $color, $value in $theme-colors {
	.btn-#{$color} {
		@if $color == "secondary" {
			@include p-button-variant(
				$background: $gray-100,
				$border: $gray-400,
				$color: $dark,
				$hover-background: darken($value, 7.5%),
				$hover-border: darken($value, 10%),
				$active-background: darken($value, 10%),
				$active-color: $white,
				$disabled-background: $input-disabled-bg,
				$disabled-color: rgba($dark, $disabled-opacity)
			);
		} @else if $color == "primary" or $color == "info" or $color == "success" or $color == "pistachio" {
			@include p-button-variant(
				$background: $value,
				$border: $value,
				$color: $white,
				$hover-color: $white,
				$active-color: $white,
				$disabled-background: $value,
				$disabled-color: rgba($white, $disabled-opacity)
			);
		} @else {
			@include p-button-variant(
				$background: $value,
				$border: $value,
				$color: color-contrast($value),
				$disabled-background: $value,
				$disabled-color: rgba(color-contrast($value), $disabled-opacity)
			);
		}

		@if $color == "secondary" {
			@include mark-on-colorful-bg();

			/*
			 * The following styles are needed, because the secondary button switches from light to dark
			 * on hover/active/focus. This is not the case for the other buttons.
			 */
			&:not(:hover, :active, .active, :focus-visible) mark {
				color: $primary-text;
				background-color: transparent;
				mix-blend-mode: normal;
			}
			&:where(:hover, :active, .active, :focus-visible) {
				code {
					color: $white;
				}
			}
		} @else if $color != "light" {
			@include typography-on-colorful-bg();
		}

		@if $color == "primary" {
			.show > &.dropdown-toggle {
				color: $white;
			}
		}

		&.dark-mode {
			@include p-button-variant(
				$background: $lighten-005,
				$border: $lightBorder,
				$color: $white,
				$hover-color: $white,
				$hover-background: $value,
				$active-color: $white,
				$disabled-background: $value,
				$disabled-color: rgba($white, $disabled-opacity)
			);

			&:not(.active, :active, :hover) {
				border-color: $lightBorder;
				background-color: $lighten-005;
				color: $white;
			}
		}

		@if $color == "secondary" {
			@media (hover: hover) {
				&:not(.btn) {
					background-color: var(--bs-btn-bg);
					&:hover,
					&.active {
						background-color: var(--bs-btn-hover-bg);
					}
				}
			}
		}

		&.disabled,
		&:disabled {
			/* stylelint-disable declaration-no-important */
			--bs-btn-active-color: var(--bs-btn-disabled-color) !important;
			--bs-btn-active-bg: var(--bs-btn-disabled-bg) !important;
			--bs-btn-active-border-color: var(--bs-btn-disabled-border-color) !important;
			/* stylelint-enable declaration-no-important */
		}
	}
}

@each $color, $value in $theme-colors {
	.btn-outline-#{$color} {
		@if $color == "primary" or $color == "pistachio" or $color == "success" or $color == "info" or $color == "warning" {
			@include p-button-outline-variant(
				$value,
				$color-hover: $white,
				$active-background: $value,
				$active-border: $value,
				$active-color: $white
			);
		} @else if $color == "light" {
			@include p-button-outline-variant(
				$white,
				$color-hover: $dark,
				$active-background: $value,
				$active-border: $value,
				$active-color: $dark
			);
		} @else {
			@include p-button-outline-variant(
				$value,
				$color-hover: color-contrast($value),
				$active-background: $value,
				$active-border: $value,
				$active-color: $white
			);
		}

		&:hover,
		&.active {
			@include typography-on-colorful-bg();
		}

		// If outline buttons get used as card-options, they would look odd with their usual border colors.
		// They are often in line with other options like a edit button.
		// So they should look the same as the usual card options, but with their text color.
		&.card-option {
			--bs-btn-bg: transparent;
		}

		&.disabled {
			&:active,
			&.active {
				background-color: var(--bs-btn-disabled-bg);
				color: var(--bs-btn-disabled-color);
			}
		}
	}

	.input-group .btn-outline-secondary:not(:is(:disabled, .disabled, .btn-frameless)) {
		border-color: $gray-400;

		&:hover,
		&.active {
			border-color: $value;
		}
	}
}

@each $color, $value in $theme-colors {
	.btn-toggle.btn-#{$color} {
		@if $color == "primary" or $color == "pistachio" or $color == "success" or $color == "info" or $color == "danger" {
			@include p-toggle-button-variant(
				$light,
				$gray-400,
				$hover-background: $value,
				$hover-border: $value,
				$hover-color: $white,
				$active-background: $value,
				$active-border: $value,
				$active-color: $white
			);

			.show > &.dropdown-toggle {
				color: $white;
			}
		} @else {
			@include p-toggle-button-variant(
				$light,
				$gray-400,
				$hover-background: $value,
				$hover-border: $value,
				$active-background: $value,
				$active-border: $value
			);
		}

		&.dark-mode {
			@include p-button-variant(
				$background: $lighten-005,
				$border: $lightBorder,
				$color: $white,
				$hover-background: $value,
				$hover-border: $value,
				$hover-color: $white,
				$active-background: $value,
				$active-border: $value,
				$active-color: $white,
				$disabled-background: $value,
				$disabled-color: rgba(color-contrast($value), $disabled-opacity)
			);
		}
	}
}

@each $color, $value in $theme-colors {
	.btn-filter {
		&.btn-#{$color} {
			@if $color == "primary" or $color == "pistachio" or $color == "success" or $color == "info" or $color == "danger"
			{
				@include p-toggle-button-variant(
					$light,
					$gray-400,
					$hover-background: $value,
					$hover-border: $value,
					$hover-color: $white,
					$active-background: $light,
					$active-border: $gray-400,
					$active-color: color-contrast($light)
				);
			} @else {
				@include p-toggle-button-variant(
					$light,
					$gray-400,
					$hover-background: $value,
					$hover-border: $value,
					$hover-color: color-contrast($value),
					$active-background: $light,
					$active-border: $gray-400,
					$active-color: color-contrast($light)
				);
			}
		}

		&.btn-outline-#{$color} {
			@include p-button-outline-variant(
				$color: $value,
				$color-hover: $value,
				$active-background: transparent,
				$active-border: $value,
				$active-color: $value
			);

			&:hover {
				background-color: $lighten-010;
			}
		}

		&.dark-mode {
			&:not(.active, :active, :disabled, .disabled) {
				border-color: $lightBorder;
				color: $white;
			}
		}
	}
}

@each $color, $value in $theme-colors {
	.btn-toggle.btn-outline-#{$color} {
		@include p-button-outline-variant($color: $value);

		@if $color == "primary" or $color == "pistachio" or $color == "success" or $color == "info" or $color == "warning" {
			@include p-button-outline-variant($color: $value, $color-hover: $white, $active-color: $white);

			&:not(:disabled, .disabled):where(:active, .active),
			.show > &.dropdown-toggle {
				color: $white;
			}
		}
	}
}

.btn {
	& * {
		hyphens: none; // avoiding hyphenation on short texts
		text-wrap: pretty; // gives nicer text as it avoids, e.g., orphans
	}
	&:not(.nowrap) {
		white-space: normal;
	}
	& .badge {
		top: 0;
	}
}

@media (hover: hover) {
	button.bg-light:not(:disabled, .disabled) {
		&:hover,
		&.active {
			/* stylelint-disable-next-line declaration-no-important */
			background-color: darken($light, 10%) !important;
		}
	}
}
