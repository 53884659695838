@import "variables";
@import "mixins/alert";
@import "./mixins/colorful-bg";

$alert-theme-colors: map-merge(
	$theme-colors,
	(
		"plain": $white,
	)
);

.alert {
	border-radius: $border-radius-xl;
	border-width: 0;

	@each $themeName, $color in $alert-theme-colors {
		&.alert-#{$themeName} {
			@if $themeName != "light" and $themeName != "plain" {
				color: $white;

				@include typography-on-colorful-bg();
			} @else {
				color: $body-color;

				.close {
					text-shadow: none;
				}
			}

			@if $themeName == "plain" {
				background-color: $white;
				border-width: $border-width;
				border-color: shift-color-level($border-color, 2);
			} @else {
				@include alert-theme-variant($themeName, $color);
			}
		}
	}
}
