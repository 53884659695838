@import "bootstrap/scss/functions";
@import "functions";

/**
 * Customized Bootstrap Vars
 * This file overwrites the bootstrap variables.
 * The bootstrap variables are defined in the file node_modules/bootstrap/scss/_variables.scss.
 *
 * It should only contain variables that are also present in bootstrap/scss/variables
 * More infos here: https://getbootstrap.com/docs/5.0/customize/sass/
 */

/* NOTE: If we change these values for some reason, it probably means we also need to change the values in getBreakpointLowerLimit(…) in file
/shared/core/window-size.service.ts and vice-versa. */

$grid-breakpoints: (
	xxs: 0,
	xs: 436px,
	sm: 576px,
	md: 768px,
	lg: 992px,
	xl: 1200px,
	xxl: 1500px,
);

$container-max-widths: (
	sm: 540px,
	md: 720px,
	lg: 960px,
	xl: 1140px,
	xxl: 1500px,
);

// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.

$spacer: 1rem;
$spacers: ();
/* stylelint-disable-next-line scss/no-duplicate-dollar-variables */
$spacers: map-merge(
	(
		0: 0,
		1: (
			$spacer * 0.25,
		),
		2: (
			$spacer * 0.5,
		),
		3: $spacer,
		4: (
			$spacer * 1.5,
		),
		5: (
			$spacer * 3,
		),
		6: (
			$spacer * 5,
		),
	),
	$spacers
);

/* Gray colors for things that are clickable */
$gray-100: #f5f5f5;
$gray-200: #eee;
$gray-300: #e0e0e0;
$gray-400: #bdbdbd;
$gray-500: #9e9e9e;
$gray-600: #757575;
$gray-700: #616161;
$gray-800: #424242;
$gray-900: #212121;

$primary: #f69a38; // Dr. Plano orange
$secondary: #525050; // Most hover effects for buttons, dropdowns etc.
$success: #35a344;
$info: #2c93a5;
$warning: #ffc107;
$danger: #d12733;
$light: $gray-100;
$dark: $gray-800; // Is also used in hard-coded form on the loading screen.
$purple: #6f42c1;
$pistachio: #98a300;
$sepia: #784717;
$white: #fff;

$theme-colors: (
	"primary": $primary,
	"secondary": $secondary,
	"success": $success,
	"info": $info,
	"warning": $warning,
	"danger": $danger,
	"light": $light,
	"dark": $dark,
	"purple": $purple,
	"pistachio": $pistachio,
	"sepia": $sepia,
);

$purple-bg-subtle: change-color($purple, $lightness: 98%);
$info-bg-subtle: change-color($info, $lightness: 98%);
$secondary-bg-subtle: change-color($secondary, $lightness: 98%);
$sepia-bg-subtle: change-color($sepia, $lightness: 98%);

$headings-font-family:
	// Cross-platform generic font family (default user interface font)
	system-ui,
	// Safari for macOS and iOS (San Francisco)
	-apple-system,
	// Chrome for macOS and iOS (San Francisco)
	BlinkMacSystemFont,
	// Windows
	"Segoe UI",
	// Android
	"Roboto",
	// Linux
	"Oxygen",
	"Ubuntu",
	"Cantarell",
	"Noto Sans",
	"Liberation Sans",
	// Basic web fallback
	"Helvetica Neue",
	"Arial",
	// Sans serif fallback
	sans-serif,
	// Emoji fonts
	"Apple Color Emoji",
	"Segoe UI Emoji",
	"Segoe UI Symbol",
	"Noto Color Emoji";
$font-family-base: $headings-font-family;
$font-family-monospace: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New",
	monospace;

/* font sizes */

/* We want to overwrite Bootstrap’s variable $font-size-base with a clamp function, but that's impossible because of calculations inside the Bootstrap code that use $font-size-base. You can not calculate like clamp(…) * 1.25. You'd need to wrap it with calc(…). Bootstrap will probably do that in their V6 🤞 Until then, we have introduced $font-size-body that should be used in our code instead of $font-size-base. */
// TODO: PLANO-184835
$font-size-body: clamp(
	0.9375rem,
	0.9189rem + 0.093vw,
	1rem
); // Usually will be 16px. We had to declare this variable because again in this file even though it is already declared in _non-bootstrap-variables.scss. This is because we want to use it here, too, but we don't want to import that file here. Maybe we can get rid of this declaration after PLANO-184835 is done.
$font-size-base: 1rem; // Typically `16px`
$line-height-base: 1.55;

$font-size-lg: $font-size-base * 1.25; // Typically `20px` if browser has `16px`
$font-size-sm: clamp(0.8125rem, 0.7939rem + 0.093vw, 0.875rem); // Typically `14px` if browser has `16px`

// We dont want to use font-weight 500 as it results in inconsistent UI in different browsers (see PLANO-187836).
// So if it ever gets used, which it should not, then we at least want take care that it does not break the UI.
// Thus, we set it to be the same as the next bolder one.
$font-weight-medium: 600;

$h1-font-size: clamp(1.9375rem, 1.8819rem + 0.278vw, 2.125rem);
$h2-font-size: clamp(1.625rem, 1.5694rem + 0.278vw, 1.8125rem);
$h3-font-size: clamp(1.25rem, 1.213rem + 0.185vw, 1.375rem);
// NOTE: This needs to be aligned well with the p-info-circle
$h4-font-size: clamp(1.0625rem, 1.0439rem + 0.093vw, 1.125rem);
$h5-font-size: $font-size-body;
$h6-font-size: clamp(0.8125rem, 0.7939rem + 0.093vw, 0.875rem); // Usually will be 14px

// $small-font-size makes a text smaller than the surrounding (em instead of rem)
$small-font-size: clamp(0.8125em, 0.7939em + 0.093vw, 0.875em);

$btn-font-size: $font-size-sm;
$dropdown-font-size: $font-size-sm;
$input-font-size: $font-size-sm;
$btn-font-size-lg: $font-size-body;
$form-label-font-size: $font-size-sm;
$badge-font-size: $font-size-sm;
$form-label-margin-bottom: map-get($spacers, 1);

// Bootstrap had issues to compile without this variable. 0.5 is the default.
$form-check-label-disabled-opacity: 0.5;

$form-text-margin-top: 0.25em;
$form-text-font-size: calc($font-size-body * 0.75); // TODO: PLANO-186576 remove this line

$body-color: $gray-900;
$headings-color: hsl(0deg 0.65% 30.39%);
$card-title-color: $headings-color;
$toast-header-color: $headings-color;
$card-color: $body-color;

$code-color: $body-color;
$pre-color: $code-color;

$link-color: $info;
$link-hover-color: shift-color-level($info, -1);
$link-decoration: none;
$link-hover-decoration: underline;

$mark-bg: transparent;
$mark-padding: 0;
$mark-color: $primary;

$btn-font-weight: 400;
$nav-link-font-weight: 600;
$form-label-font-weight: 600;
$calendar-day-header-font-weight: 600;
$badge-font-weight: 400;
$table-border-color: #adb5bd;
/* The following color for $table-hover-bg equals the color of hovered list items. See global.styles.scss. */
$table-hover-bg: hsl(216deg 33% 97% / 50%);

$toast-max-width: min(380px, 92vw);

/* One can easily change the box-shadow color (e.g. to match a card's border color) by defining --p-box-shadow-color in the component. If it's not defined, $secondary will be the fallback color. */
$box-shadow-sm:
	0 1px 3px -1px color-mix(in srgb, var(--p-box-shadow-color, $secondary) 25%, transparent),
	0 2px 5px -1px color-mix(in srgb, var(--p-box-shadow-color, $secondary) 20%, transparent);
$box-shadow:
	0 3px 6px color-mix(in srgb, var(--p-box-shadow-color, $secondary) 25%, transparent),
	0 12px 20px color-mix(in srgb, var(--p-box-shadow-color, $secondary) 10%, transparent);
$box-shadow-lg:
	0 5px 10px color-mix(in srgb, var(--p-box-shadow-color, $secondary) 30%, transparent),
	0 7px 28px color-mix(in srgb, var(--p-box-shadow-color, $secondary) 25%, transparent);

$toast-box-shadow: $box-shadow-lg;
$dropdown-box-shadow: $box-shadow;

$card-spacer-x: 1rem;
$input-bg: $white;
$input-disabled-bg: rgb(243 244 246);
$input-group-addon-bg: $input-disabled-bg;

// we want to have the same border radius for every button
$btn-border-radius: 0.25rem;
$btn-border-radius-lg: 0.25rem;
$btn-border-radius-sm: 0.25rem;

$badge-border-radius: 0.25rem;

$border-radius-xl: 0.6rem;

$modal-fade-transform: none;

// The default margin for the hr is 1rem. We want to have it smaller.
$hr-margin-y: map-get($spacers, 2);
