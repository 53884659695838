/*
 * This is Planos Custom Bootstrap file
 */

/* Our variables and Bootstrap’s together */
@import "variables";

/* Extend bootstrap utilities */
@import "./bootstrap/utilities";

/* Bootstrap itself */
@import "bootstrap/scss/bootstrap.scss";

/* Utility classes which are written in the same way as the bootstrap classes */
@import "./p-utilities";
