* {
	// Prevent zoom on ios as safari ignores user-scalable=no meta parameter.
	// See PLANO-24168
	/* stylelint-disable-next-line plugin/no-unsupported-browser-features */
	touch-action: pan-x pan-y;
}

// Safari on ios zooms automatically on focus if the input/textarea has a font-size below 16px.
_::-webkit-full-page-media,
_:future,
:root input,
:root textarea {
	/* stylelint-disable-next-line sh-waqar/declaration-use-variable */
	font-size: 16px;
}
