.angular-editor-wrapper {
	background: $white;
}

.angular-editor-button.active {
	// stylelint-disable-next-line declaration-no-important
	background: $primary !important;
	color: $white;
}

.angular-editor-button .fa-remove::before {
	/* stylelint-disable-next-line declaration-no-important */
	content: "" !important;
}

.angular-editor .angular-editor-wrapper .angular-editor-textarea {
	// stylelint-disable-next-line declaration-no-important
	border-top: 0 !important;
	// stylelint-disable-next-line declaration-no-important
	margin-top: 0 !important;
	// stylelint-disable-next-line declaration-no-important
	border-radius: 0 0 0.25rem 0.25rem !important;
}

.angular-editor-toolbar {
	border-radius: 0.25rem 0.25rem 0 0;
}
