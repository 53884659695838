@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/variables-dark";
@import "bootstrap/scss/maps";
@import "bootstrap/scss/mixins";
@import "bootstrap/scss/utilities";

/**
 * This file is used to modify utility classes inside the bootstrap framework.
 *
 * Besides extending bootstraps utility classes, you can also
 * - change the styles of utility classes
 * - change the name of the set of utility classes
 * - remove a set of utility classes
 *
 * Learn more about how to use Bootstrap’s API for that, here: {@link https://getbootstrap.com/docs/5.0/utilities/api/#using-the-api}
 */
$utilities: map-merge(
	$utilities,
	(
		"font-size": (
			rfs: true,
			property: font-size,
			class: fs,
			values:
				map-merge(
					$font-sizes,
					(
						"xs": $font-size-xs,
						"sm": $font-size-sm,
						"lg": $font-size-lg-clamp,
					)
				),
		),
		"shadow": (
			property: box-shadow,
			class: shadow,
			values: (
				null: $box-shadow,
				sm: $box-shadow-sm,
				lg: $box-shadow-lg,
			),
		),
		"text": (
			property: color,
			class: text,
			values: (
				light: hsl(0 0% 75% / 1),
			),
		),
	)
);
