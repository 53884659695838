@import "./mixins/colorful-bg";

.transparent-background .modal-content {
	// stylelint-disable-next-line declaration-no-important
	background-color: transparent !important;
	width: fit-content;
	// stylelint-disable-next-line declaration-no-important
	border: none !important;
}

.modal-body {
	display: flex;
	flex-direction: column;
}

.modal-header.modal-header-content,
.modal-footer > .modal-footer-content {
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: map-get($spacers, 3);
}

.modal-footer {
	// Container query parent
	container-type: inline-size;

	// If this button has an icon, hide the text on mobile to save space
	&:has(.modal-close-button) .btn:has(fa-icon) {
		// The dismiss button should only show the text on desktop to make it look less prominent than
		// other buttons. On mobile it will show only the icon.
		&.modal-dismiss-button {
			fa-icon {
				display: none;
			}
		}

		@container (inline-size < 400px) {
			// We want to hide all the ng-content as usually it contains the text.
			// But there can be cases where the icon and the text got provided as ng-content like
			// `<p-button><fa-icon icon="pen" rotate="270" />Some text</p-button>`
			// In this case we want to keep everything as it is.
			// Also if this is the close button, we want to keep it unchanged.
			&:not(.modal-close-button) .ng-content-wrapper:not(:has(fa-icon)) {
				display: none;
			}

			// The dismiss button's icon is hidden on desktop, so we need to make it visible again
			&.modal-dismiss-button {
				fa-icon {
					display: block;
				}
			}
		}
	}

	// If there is no close button in the footer, there is no need to save space by hiding the text and showing the icon.
	// So in this case we show the dismiss button text only.
	&:not(:has(.modal-close-button)) .btn:has(fa-icon).modal-dismiss-button fa-icon {
		display: none;
	}

	.modal-footer-content {
		flex-grow: 1;
	}
}

.modal-dialog-scrollable {
	.modal-header {
		@extend .shadow-sm;
		// z-index here fixes that the shadow gets overlaid by e.g. collapsible inside modal-content
		z-index: $z-index-sticky + 1;
	}

	&.modal-has-scroll .modal-footer {
		// stylelint-disable-next-line declaration-no-important
		box-shadow: 0 -0.125rem 0.25rem $darken-0125 !important;
		// z-index here fixes that the shadow gets overlaid by e.g. collapsible inside modal-content
		z-index: 0;
	}
}

p-modal-content:has(p-tabs.align-modal-header-with-tabs) {
	.modal-header {
		/* stylelint-disable-next-line declaration-no-important */
		padding-inline: map-get($spacers, 4) !important;
	}
}

.modal-dialog-scrollable .modal-content {
	&:not(.max-height-modal) {
		max-height: calc(100vh - (4.5rem + 1.75rem));
		// stylelint-disable-next-line declaration-block-no-duplicate-properties
		max-height: calc(100svh - (4.5rem + 1.75rem));
	}
	&.max-height-modal {
		min-height: calc(100vh - (4.5rem + 1.75rem));
		// stylelint-disable-next-line declaration-block-no-duplicate-properties
		min-height: calc(100svh - (4.5rem + 1.75rem));

		p-modal-content {
			flex-grow: 1;
			.wrapper-modal {
				flex-grow: 1;
			}
		}
	}
}

.modal-dialog {
	// need to recenter the modal, since we changed the size
	margin-inline: auto;
	max-width: min(95%, 60ch);

	--bs-modal-margin: #{$p-navbar-height * 1.2};

	&.modal-fullscreen {
		margin: 0;
		max-width: 100%;
		height: 100vh;
		// stylelint-disable-next-line declaration-block-no-duplicate-properties
		height: 100svh;
		position: relative;

		&.modal-dialog-scrollable,
		.modal-content {
			max-height: none;
		}

		.modal-light {
			background-color: $white;
		}

		.modal-content {
			display: flex;
			align-items: stretch;
			justify-content: stretch;
			flex-direction: column;
			border-radius: 0;
			border: none;
			height: 100%;

			.modal-body > .modal-body-content,
			.modal-header > .modal-header-content,
			.modal-footer > .modal-footer-content {
				margin: 0 auto;
			}

			.modal-body > .modal-body-content {
				position: relative;
				flex-grow: 1;
				display: flex;
				flex-direction: column;
				width: 100%;
			}
		}

		@include media-breakpoint-up(lg) {
			max-height: calc(100vh - (4.5rem + 1.75rem));
			// stylelint-disable-next-line declaration-block-no-duplicate-properties
			max-height: calc(100svh - (4.5rem + 1.75rem));
		}

		.modal-footer {
			// Increase the padding bottom on full screen mobiles to facilitate the user to reach the buttons on mobile
			padding-bottom: map-get($spacers, 3);
		}
	}
}

.modal {
	&.fade .modal-dialog {
		animation: p-fade-in 0.3s ease-out;
	}
}

/*
 * Sometimes the modal and it’s backdrop are not in the correct order in the DOM, which causes the backdrop to cover
 * the modal. This is a workaround to fix this issue.
 * See https://github.com/ng-bootstrap/ng-bootstrap/issues/2686#issuecomment-2373572061
 */
.modal,
.modal-backdrop {
	// stylelint-disable-next-line declaration-no-important
	z-index: $z-index-modal - 1 !important;
}
ngb-modal-window:last-of-type {
	// stylelint-disable-next-line declaration-no-important
	z-index: $z-index-modal !important;
}

.modal-open {
	.modal-backdrop {
		&.show {
			opacity: 0.5;
		}

		&.not-clickable {
			opacity: 1;
		}
	}

	.modal {
		-webkit-overflow-scrolling: touch;
		overflow-y: auto;
	}
}

.date-picker-modal {
	.modal-dialog.modal-sm {
		max-width: 300px;
	}
}

@include media-breakpoint-up(md) {
	.modal-md,
	.modal-lg,
	.modal-xl {
		max-width: min(90%, 90ch);
	}
}

@include media-breakpoint-up(lg) {
	.modal-lg {
		max-width: min(90%, 120ch);
	}
	.text-lg-nowrap {
		// stylelint-disable-next-line declaration-no-important
		white-space: nowrap !important;
	}
}

@include media-breakpoint-up(xl) {
	.modal-xl {
		max-width: min(90%, 126ch);
	}
}

/* stylelint-disable no-descending-specificity */
.modal-header {
	padding: map-get($spacers, 2) map-get($spacers, 3);

	h1,
	.h1,
	h2,
	.h2,
	h3,
	.h3,
	h4,
	.h4,
	h5,
	.h5,
	h6,
	.h6 {
		margin-bottom: 0;
	}
}

.modal-content {
	// NOTE: needed for shift-detail component in modal
	overflow: initial;

	margin-inline: auto;

	> img {
		border-radius: $border-radius;
	}
}

.modal-footer {
	justify-content: space-between;
	padding: map-get($spacers, 2) map-get($spacers, 3);
}

@mixin modal-theme-variant($themeName, $color) {
	.modal-#{$themeName} {
		.modal-content {
			background-color: $color;
		}

		&:not(.modal-white, .modal-light) {
			@include typography-on-colorful-bg();
		}

		//TODO: PLANO-186118: Remove this once the modal themes are fixed and without opacities
		&:not(.modal-white) {
			.modal-header {
				background-color: $darken-003;
				border-bottom: $border-width solid $darken-0125;
			}

			.modal-footer {
				border-top: $border-width solid $darken-0125;
				background-color: $darken-003;
			}
		}

		&.modal-white {
			.modal-header {
				background-color: #f8f8f8;
				border-bottom: $border-width solid $darken-0125;
			}

			.modal-footer {
				border-top: $border-width solid $darken-0125;
				background-color: #f8f8f8;
			}
		}
	}
}

$modal-theme-colors: map-merge(
	$theme-colors,
	(
		"white": $white,
	)
);

@each $themeName, $color in $modal-theme-colors {
	@include modal-theme-variant($themeName, $color);
}

.modal-warning {
	color: $body-color;
}
/* stylelint-enable */

// HACK: Please read the comment at https://github.com/twbs/bootstrap/blob/v5.2.3/scss/_modal.scss#L172.
// Bootstrap plans to remove the margin in v6 with gap. Till then we will already use gap here.
// Different from Bootstrap we don’t use `.modal-footer > *` here, because if there is a `.row`, it would break the UI
// if we would overwrite its margin and as .row is already overwriting the margin, we don’t need to do it ourselves.
/* stylelint-disable-next-line no-descending-specificity */
.modal-footer {
	gap: var(--#{$prefix}modal-footer-gap);

	> :not(.row) {
		/* stylelint-disable-next-line sh-waqar/declaration-use-variable -- ok, since this is just a workaround */
		margin: initial;
	}
	> .row {
		margin-bottom: 0;
	}
}

// If the passed modal-form has flex-grow-1, stretch also the wrapping one
p-editable-modal-form:has(p-editable-modal-form.flex-grow-1) {
	flex-grow: 1;
}

.p-tooltip-show-more-modal .modal-body {
	// There is already a dismiss button on the footer, so we don't need the one in the header
	button:has(fa-icon[aria-label="icon(xmark)"]) {
		display: none;
	}
	padding: 0;
}
