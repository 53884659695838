/* stylelint-disable selector-no-vendor-prefix */
.form-control::-ms-input-placeholder {
	color: $gray-cold-200;
} /* Microsoft Edge */
.form-control::-moz-placeholder {
	color: $gray-cold-200;
} /* Mozilla Firefox 19+ */
.form-control::-webkit-input-placeholder {
	color: $gray-cold-200;
} /* WebKit, Blink, Edge */
.form-control:-moz-placeholder {
	color: $gray-cold-200;
} /* Mozilla Firefox 4 to 18 */
.form-control:-ms-input-placeholder {
	color: $gray-cold-200;
} /* Internet Explorer 10-11 */
/* stylelint-enable */
