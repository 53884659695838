.bg-light-cold {
	// stylelint-disable-next-line declaration-no-important
	background-color: $gray-cold-100 !important;
}

.bg-dark-cold {
	background-color: $gray-cold-200;
}

.bg-dark {
	background-color: $dark;
}

.bg-darker {
	background-color: $gray-900;
}

.bg-purple-subtle {
	background-color: $purple-bg-subtle;
}

.bg-sepia-subtle {
	background-color: $sepia-bg-subtle;
}
