@import "./mixins/colorful-bg";

$tooltip-card-max-height: min(600px, 60svh);

.float-ui-arrow:not(.has-color) {
	background-color: $gray-400;
}

.float-ui-arrow.has-color {
	visibility: visible;
}

.float-ui-container[data-float-ui-placement="left-end"] > .float-ui-arrow,
.float-ui-container[data-float-ui-placement="right-end"] > .float-ui-arrow {
	// stylelint-disable-next-line declaration-no-important
	top: 5px !important;
}

.float-ui-container[data-float-ui-placement="left-start"] > .float-ui-arrow,
.float-ui-container[data-float-ui-placement="right-start"] > .float-ui-arrow {
	// stylelint-disable-next-line declaration-no-important
	top: -5px !important;
}

.float-ui-container[data-float-ui-placement^="top"] > .float-ui-arrow {
	// stylelint-disable-next-line declaration-no-important
	bottom: -5px !important;
}
.float-ui-container[data-float-ui-placement^="bottom"] > .float-ui-arrow {
	// stylelint-disable-next-line declaration-no-important
	top: -5px !important;
}

.float-ui-container[data-float-ui-placement="bottom"] > .float-ui-arrow {
	// stylelint-disable-next-line declaration-no-important
	border-right: none !important;
	// stylelint-disable-next-line declaration-no-important
	border-bottom: none !important;
}
.float-ui-container[data-float-ui-placement="top"] > .float-ui-arrow {
	// stylelint-disable-next-line declaration-no-important
	border-top: none !important;
	// stylelint-disable-next-line declaration-no-important
	border-left: none !important;
}
.float-ui-container[data-float-ui-placement="right"] > .float-ui-arrow {
	// stylelint-disable-next-line declaration-no-important
	border-top: none !important;
	// stylelint-disable-next-line declaration-no-important
	border-right: none !important;
}
.float-ui-container[data-float-ui-placement="left"] > .float-ui-arrow {
	// stylelint-disable-next-line declaration-no-important
	border-bottom: none !important;
	// stylelint-disable-next-line declaration-no-important
	border-left: none !important;
}

float-ui-content {
	.float-ui-container {
		z-index: 1021;
		border: 0;
		padding: 0;
		margin: 0;
		border-radius: $card-border-radius;
		box-shadow: $box-shadow-lg;
	}

	& .card {
		margin: 0;
	}

	&.p-tooltip .ngxp__inner .card {
		max-height: $tooltip-card-max-height;
		overflow: hidden;
		position: relative;
	}

	.float-ui-container {
		&[data-float-ui-placement="left"] > .float-ui-arrow.bg-dark {
			right: -1px;
		}
		&[data-float-ui-placement="right"] > .float-ui-arrow.bg-dark {
			left: -1px;
		}
		&[data-float-ui-placement="top"] > .float-ui-arrow.bg-dark {
			bottom: -1px;
		}
		&[data-float-ui-placement="bottom"] > .float-ui-arrow.bg-dark {
			top: -1px;
		}
		& .float-ui-arrow.has-color.bg-dark {
			box-sizing: content-box;
			border-width: $border-width;
			border-style: solid;
			border-color: gray;
		}
	}

	&.p-html-tooltip {
		animation: p-fade-in 0.1s ease-in-out forwards;

		.float-ui-container {
			max-width: none;
			min-width: fit-content;
			width: fit-content;
		}
	}

	&.p-typeahead {
		.float-ui-container {
			width: min(250px, 80vw);
			max-height: min(300px, 80vh);
			box-shadow: $box-shadow;
			overflow: auto;
			&::-webkit-scrollbar {
				/* stylelint-disable-next-line declaration-no-important -- override set background-color for scrollbar */
				background-color: $white !important;
			}
		}

		@media (hover: none) {
			.btn-outline-secondary.active {
				/* stylelint-disable declaration-no-important */
				color: var(--bs-btn-active-color) !important;
				background-color: var(--bs-btn-active-bg) !important;
				border-color: var(--bs-btn-active-border-color) !important;
				/* stylelint-enable declaration-no-important */
			}
		}
	}

	z-index: $z-index-tooltip;
	text-align: left;
}

.bs-tooltip-left .arrow,
.bs-tooltip-right .arrow {
	top: 50%;
	transform: translateY(-50%);
}

.show-more-option-wrapper {
	position: absolute;
	inset: 0;
	container-type: size;
	pointer-events: none;
	@include link-on-colorful-bg();
}
.show-more-option {
	// reduce 3 pixels for the borders and one more pixel for actual overflow
	@container (max-height: calc(#{$tooltip-card-max-height} - 3px)) {
		display: none;
	}
	position: absolute;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: $z-index-tooltip;
	pointer-events: none;
	a {
		pointer-events: all;
	}
}
