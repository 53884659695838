@mixin hover-and-focus-slick-styles {
	text-decoration: none;
}

.slick-prev,
.slick-next {
	position: absolute;
	top: 50%;
	/* stylelint-disable-next-line sh-waqar/declaration-use-variable */
	font-size: 1.3rem;

	@media (hover: hover) {
		&:hover {
			@include hover-and-focus-slick-styles;
		}
	}

	&:focus,
	&:active {
		@include hover-and-focus-slick-styles;
	}
}

.slick-arrow {
	right: 0;
	left: 0;
	// stylelint-disable-next-line declaration-no-important
	display: none !important;
}
@include media-breakpoint-up(sm) {
	.slick-arrow {
		display: inline;
	}

	.slick-prev {
		left: -44px;
	}

	.slick-next {
		right: -44px;
	}
}

// nav dots
.slick-dots {
	display: flex;
	justify-content: center;
	padding: 0;

	li {
		list-style-type: none;
		margin: 0 map-get($map: $spacers, $key: 2);

		@include media-breakpoint-down(md) {
			padding-left: 0;
		}

		&.slick-active button,
		button:hover {
			border: $border-width solid gray;
			background-color: gray;
		}

		/* stylelint-disable-next-line no-descending-specificity */
		button {
			height: 16px;
			width: 16px;
			border-radius: 50%;
			border: $border-width solid $gray-400;
			font-size: 0;
			background-color: $light;
		}
	}
}
