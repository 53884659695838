@import "variables";

.dropdown-item-btn {
	margin-bottom: 0;
	position: relative;
}

.dropdown-button-group {
	display: flex;
	p-dropdown .btn {
		border-top-right-radius: 0;
		border-bottom-right-radius: 0;
		/* stylelint-disable declaration-no-important */
		border-top-left-radius: $btn-border-radius !important;
		border-bottom-left-radius: $btn-border-radius !important;
		/* stylelint-enable declaration-no-important */
		border-right-width: 0;
	}
	p-button .btn {
		border-top-left-radius: 0;
		border-bottom-left-radius: 0;
	}
}

.dropdown-menu > .dropdown-item,
.dropdown .btn:not(.btn-lg, .btn-sm) {
	font-size: $dropdown-font-size;
}
