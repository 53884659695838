@import "functions";

.ngb-dp-month,
.ngb-dp-weekday,
.ngb-dp-day {
	flex-grow: 1;
}

// This rule ensures that in the last row of the month, the selected days do not overflow the border radius of the entire datepicker.
.ngb-dp-week:last-of-type {
	/* stylelint-disable-next-line declaration-no-important */
	border-top-left-radius: 0 !important;
	/* stylelint-disable-next-line declaration-no-important */
	border-top-right-radius: 0 !important;
	overflow: hidden;
}

.ngb-dp-month .ngb-dp-week {
	/* stylelint-disable-next-line declaration-no-important */
	padding: 0 !important;
}

.ngb-dp-day {
	display: flex;

	> * {
		flex-grow: 1;
		text-align: center;
		align-content: center;
	}
}

// prevent auto zoom for datepicker on iOS
ngb-datepicker select {
	// stylelint-disable-next-line declaration-no-important
	font-size: $font-size-body !important;
}

ngb-datepicker .ngb-dp-day:focus {
	box-shadow: $input-focus-box-shadow;
	border-radius: $border-radius-sm;
}

@media (hover: hover) {
	.ngb-dp-range-day .ngb-dp-day:hover {
		background: shift-color-level($light, 2);
	}
}

@media (hover: hover) {
	.ngb-dp-range-week .ngb-dp-week:hover {
		background: shift-color-level($light, 2);
	}
}

@media (hover: hover) {
	.ngb-dp-range-month .ngb-dp-month:hover .day-btn:not(.outside-current-month) {
		background: shift-color-level($light, 1);
	}
}

.ngb-dp-weekday {
	// stylelint-disable-next-line declaration-no-important
	color: $primary !important;
}

.ngb-dp-today > *:not(.text-white) {
	// stylelint-disable-next-line declaration-no-important
	color: $primary !important;
}

.ngb-dp-arrow {
	align-items: center;
	.btn-link {
		/* stylelint-disable-next-line declaration-no-important */
		color: $primary !important;

		&:where([disabled], .disabled, :disabled) {
			/* On the primary color, the default opacity is not enough to see the difference to an enabled button. */
			opacity: calc($disabled-opacity / 2);
		}

		&:not([disabled], .disabled, :disabled):hover {
			/* stylelint-disable-next-line declaration-no-important */
			color: darken($primary, 15%) !important;
		}
	}
}
