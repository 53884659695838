@import "functions";

$cal-bg-primary: transparent;
$cal-bg-secondary: transparent;
$cal-bg-active: transparent;
$cal-today-bg: transparent;
$cal-weekend-color: transparent;

[precision="minutes"] .cal-week-view .cal-events-row {
	height: 24px;
}

[precision="days"] .cal-week-view .cal-events-row {
	height: 36px;
}

.cal-month-view {
	background-color: transparent;

	@media (hover: hover) {
		.cal-cell-row:hover {
			// stylelint-disable-next-line declaration-no-important
			background-color: inherit !important;
		}
	}

	.cal-cell-row.cal-header {
		cursor: initial;

		@media (hover: hover) {
			.cal-cell:hover {
				// stylelint-disable-next-line declaration-no-important
				background: none !important;
			}
		}
	}
}

// FIXME: PLANO-1389 Im Kalender auch Schichten des vorherigen/nächsten Monats anzeigen
.cal-out-month {
	p-all-day-items-list,
	.cal-day-number {
		display: none;
	}

	.cal-cell-top > * {
		// stylelint-disable-next-line declaration-no-important
		display: none !important;
	}

	.cal-today {
		// stylelint-disable-next-line declaration-no-important
		background-color: transparent !important;
	}
}

.cal-cell.cal-past,
.cal-cell.cal-past:hover,
.cal-cell-row .cal-cell.cal-past,
.cal-cell-row .cal-cell.cal-past:hover {
	background: $gray-100;
	p-all-day-item,
	.all-day-event-cover {
		// stylelint-disable-next-line declaration-no-important
		background-color: $gray-100 !important;
	}
}

.cal-cell-row {
	.cal-cell.cal-day-cell {
		width: calc(100% / 7);

		// NOTE: 	We needed to get rid of our previously used `overflow-hidden` in order to combine
		// 				- our tooltips,
		// 				- our leave-list (with pos absolute) and
		// 				- the angular-calendar

		// NOTE: 	angular-calendar sets .cal-cell { flex-basis: 0%; }
		// 				Firefox does acts different then other browsers then.
		// 				flex-basis: auto; fixes this issue.

		flex-basis: auto;
		flex-wrap: wrap;

		&:focus {
			background: $darken-015;
		}
	}
}

.cal-week-view .cal-cell-row .cal-cell:hover {
	background: transparent;
}

.cal-cell-top .sticky-note-button.only-show-on-hover,
.cal-cell-top .multi-select-checkbox.only-show-on-hover {
	// stylelint-disable-next-line declaration-no-important
	display: none !important;
}

.cal-cell-top:hover .sticky-note-button.only-show-on-hover,
.cal-cell-top:hover .multi-select-checkbox.only-show-on-hover {
	// stylelint-disable-next-line declaration-no-important
	display: flex !important;
}

.cal-day-cell {
	.sticky-note-button .btn {
		border-color: transparent;

		@nest &:hover & {
			border-color: shift-color-level($light, 2);
		}
	}
}

p-cell-top,
.day-header-content {
	/* Ensure the checkbox is also visible on grayish backgrounds but does not cover the border of the cell (e.g., in the leave calendar). On the current day, which has bg-primary, "luminosity" works better than "multiply" when the checkbox is checked.  */
	& p-checkbox {
		mix-blend-mode: multiply;
	}

	&:where(.cal-today, .is-today) p-checkbox:has(.checked) {
		mix-blend-mode: luminosity;
	}
}
