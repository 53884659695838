@import "variables";

mat-step-header {
	display: flex;
	align-items: center;

	.mat-step-icon {
		width: $font-size-body;
		height: $font-size-body;
		&.mat-step-icon-selected {
			background-color: $dark;
		}

		&.mat-step-icon-state-edit {
			background-color: rgba(0, 0, 0, 0.12); // this color matches the color of the line connecting the step dots
		}
		.mat-step-icon-content {
			display: none;
		}
	}

	.mat-step-label {
		// stylelint-disable-next-line declaration-no-important
		font-size: $font-size-body !important;
		&.mat-step-label-selected {
			.mat-step-text-label {
				font-weight: $font-weight-semibold;
				opacity: 0.85;
			}
		}
	}

	@include media-breakpoint-up(md) {
		justify-content: center;
		flex-direction: column-reverse;
		.mat-step-label {
			.mat-step-text-label {
				margin-bottom: map-get($spacers, 2);
			}
		}
	}

	@include media-breakpoint-down(lg) {
		justify-content: flex-start;
		.mat-step-label {
			.mat-step-text-label {
				margin-bottom: 0;
			}
		}

		&.mat-vertical-stepper-header {
			padding-inline: 0;
		}
	}
}

.mat-ripple {
	// stylelint-disable-next-line declaration-no-important
	display: none !important;
}

.mat-horizontal-stepper-header-container {
	justify-content: center;
	margin: map-get($spacers, 3) 0 map-get($spacers, 5) 0;
	margin-inline: auto;
	min-width: min(100%, 600px);
}

.mat-step-header {
	&:hover {
		// stylelint-disable-next-line declaration-no-important
		background-color: transparent !important;
	}
}

.mat-stepper-horizontal-line {
	// stylelint-disable-next-line declaration-no-important
	border-top-width: 0.2rem !important;
	translate: 0 $font-size-body;
}

mat-stepper {
	& * {
		font-family: $font-family-base;
	}

	&:not(.only-one-step) {
		.mat-stepper-vertical-line::before {
			// stylelint-disable-next-line declaration-no-important
			border-left-width: 0.2rem !important;
			translate: -50%; // This ensures the line and the dots are centrally aligned.
		}
		.mat-vertical-content-container {
			/* stylelint-disable-next-line sh-waqar/declaration-use-variable, declaration-no-important */
			margin-left: calc($font-size-body / 2) !important;
		}

		.mat-vertical-content {
			// stylelint-disable-next-line declaration-no-important
			padding: 0 map-get($spacers, 4) !important;
		}
	}
	&.only-one-step {
		.mat-vertical-content-container {
			// stylelint-disable-next-line declaration-no-important
			margin-left: 0 !important;
		}

		.mat-vertical-content {
			// stylelint-disable-next-line declaration-no-important
			padding: 0 !important;
		}
	}
}

.mat-horizontal-content-container {
	// stylelint-disable-next-line declaration-no-important
	padding: 0 !important;
}

.only-one-step {
	.mat-horizontal-stepper-header-container,
	.mat-step-header {
		// stylelint-disable-next-line declaration-no-important
		display: none !important;
	}

	padding-top: map-get($spacers, 4); // ensures enough whitespace between the modal's header and the content
}
