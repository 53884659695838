@import "functions";
@import "variables";
@import "./mixins/colorful-bg";

$card-theme-colors: map-merge(
	$theme-colors,
	(
		"light-cold": $gray-cold-100,
		"plain": transparent,
	)
);

/* Usually, the last <p> inside a card shouldn't have a margin-bottom as it adds up to the cards padding and the whitespace gets inconsistent. */
.card-body p:last-child {
	margin-bottom: 0;
}

.card {
	margin-bottom: map-get($spacers, 3);

	/*
	 * In case we use .card in combination with .card-body, we don’t have other child nodes.
	 * But .card sets the display to flex, which would set the computed display of all children to block, even if they are
	 * inline elements.
	 * So we reset the display to block here.
	 */
	&.card-body {
		display: block;
	}

	&.selectable {
		transition: border 0.3s;
		cursor: pointer;

		&.hovered {
			border-color: shift-color-level($primary, -4);
		}
	}

	@each $color, $value in $card-theme-colors {
		&.bg-#{$color} {
			@if $color != "light" and $color != "plain" and $color != "light-cold" {
				color: $white;

				.card-body {
					color: $white;
				}

				@include typography-on-colorful-bg();
			}
		}
	}

	.form-control-read-mode {
		// stylelint-disable-next-line declaration-no-important
		background-color: $darken-003 !important;
	}

	&.bg-dark .card-body a:not(.list-group-item) {
		text-decoration: underline;
	}

	> .list-group:first-child {
		.list-group-item:first-child {
			border-top-left-radius: inherit;
			border-top-right-radius: inherit;
		}
	}

	> .list-group:last-child {
		.list-group-item:last-child {
			border-bottom-left-radius: inherit;
			border-bottom-right-radius: inherit;

			@nest list-item & {
				border-bottom-left-radius: inherit;
				border-bottom-right-radius: inherit;
			}
		}

		list-item:last-child {
			border-bottom-left-radius: inherit;
			border-bottom-right-radius: inherit;
		}
	}

	> table tr:first-child > td {
		border-top: 0;
	}
}

$card-header-padding-y: 0.75rem;
$card-header-padding-x: 1rem;

.card-header {
	padding: $card-header-padding-y $card-header-padding-x;
	.card-header-tabs {
		margin-bottom: $card-header-padding-y * -1;
	}
}

// TODO: PLANO-187884 Decide how to continue with .card-options
.card-options {
	display: flex;
	align-items: stretch;
	justify-content: space-between;
	flex-direction: row;
	padding: 0;

	.card-option {
		border-left-width: $border-width;
		border-left-style: solid;
		border-right-width: 0;
		border-top-width: 0;
		margin: 0;
		min-width: 40px;
		border-radius: 0;
		padding-inline: map-get($spacers, 2);

		&.ms-auto {
			// The card options where meant to be used in headers of cards. If one has applied ms-auto to the card-option it
			// meant that this option should be at the right corner of a card header, thus we adjust the border-radius’s
			// accordingly.
			border-bottom-left-radius: 0.25rem;
			border-top-left-radius: 0;
			border-top-right-radius: 0;
			border-bottom-right-radius: 0;
		}
	}
}
