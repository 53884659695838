// @import "bootstrap/scss/mixins/alert";

@mixin alert-theme-border($color) {
	border-color: color.scale($color, $lightness: -13%);
}

@mixin alert-theme-variant($themeName, $color) {
	&.alert-#{$themeName} {
		@include alert-theme-bg($color);
		@include alert-theme-border($color);
	}
}
