// All items should be in one row
// Every item should take the same amount of space
.list {
	container-type: inline-size;

	// Styles that should be the same for header and content items
	.list-headline,
	.list-content .list-item {
		display: flex;
	}

	// Styles that should be the same for header-item values and list-item values
	.list-headline .list-headline-item,
	.list-content .list-item .list-item-value {
		flex: 1;
		display: flex;
		align-items: center;
		overflow: hidden;
	}

	&.frameless {
		.list-item {
			border-top: none;
			border-left: none;
			border-right: none;
			&:last-child {
				border-bottom: none;
			}
		}
	}
}
