@import "variables";

.over-tawk {
	z-index: $z-index-tawk;
}

.pb-tawk {
	/* stylelint-disable-next-line sh-waqar/declaration-use-variable, declaration-no-important */
	padding-bottom: 200px !important;

	.is-mobile & {
		/* stylelint-disable-next-line sh-waqar/declaration-use-variable, declaration-no-important */
		padding-bottom: 120px !important; // We need some space to not collide with 'create' buttons PLANO-39756
	}
}
