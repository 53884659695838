.select-activity-btn,
.activity-area-select {
	/* stylelint-disable-next-line declaration-no-important */
	display: none !important;
}

@media (hover: hover) {
	p-activity-list-item:hover {
		.select-activity-btn {
			/* stylelint-disable-next-line declaration-no-important */
			display: block !important;
		}
	}

	.activity-area-headline:hover {
		.activity-area-select {
			/* stylelint-disable-next-line declaration-no-important */
			display: block !important;
		}
	}
}
