@import "variables";
@import "./mixins/colorful-bg";

$toast-theme-colors: map-merge(
	$theme-colors,
	(
		"light-cold": $gray-cold-100,
		"plain": $white,
	)
);

.toast {
	border-width: 0;
	border-radius: $border-radius-xl;

	@each $themeName, $color in $toast-theme-colors {
		&.bg-#{$themeName} {
			@if $themeName != "light" and $themeName != "plain" and $themeName != "light-cold" {
				color: $white;
				@include typography-on-colorful-bg();
				.toast-header {
					color: inherit;
				}
			}

			@if $themeName == "plain" {
				background-color: $white;
			} @else {
				@include alert-theme-bg($color);
			}
		}
	}

	.alert {
		margin-bottom: 0;
	}
}
