table {
	& :where(h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6) {
		margin-bottom: 0;
	}

	/* Even though "border-collapse: collapse" looks better, we have to go with "separate" and tweak the borders of the <td> and <th> elements to avoid double borders, as there's a know issue: In some browsers, when you set the <thead> to "position: sticky", the borders aren’t properly included in the sticky element’s layout. As a result, while the header content sticks correctly, its borders don’t "stick" along, leaving a small transparent gap where the borders should be. See the issues described here: https://caniuse.com/css-sticky and here: https://bugzilla.mozilla.org/show_bug.cgi?id=1727594 */
	border-collapse: separate;
	border-spacing: 0;

	td {
		/* stylelint-disable-next-line sh-waqar/declaration-use-variable */
		padding: 0.75rem;
		border-top: 0;
		label {
			/* stylelint-disable-next-line declaration-no-important */
			padding: 0 !important;
		}
	}

	tr:not(:last-of-type)td {
		border-bottom: $border-width solid $table-border-color;
	}
}

.table > :not(caption) > td > * {
	padding: 0;
	color: inherit;
	background-color: inherit;
	border-bottom-width: $border-width;
	box-shadow: none;
}
