@import "variables";

em-emoji-picker {
	max-width: none;
}

.remove-emoji-btn {
	animation: p-fade-in 50ms ease-in-out forwards;
}

float-ui-content.remove-emoji-btn-tooltip .float-ui-container {
	width: fit-content;
}

.emoji-mart-emoji {
	display: flex;
	align-items: center;
	justify-content: center;
}
